import { StrictMode, useEffect, useRef, useState } from 'react';

import { ArtifactCard } from '@game/common/ArtifactCard';
import { Card } from '@game/common/Card';
import LoadingSpinner from '@game/common/LoadingSpinner';

import type { Entity, Story } from '@hiddendoor/shared';
import { feed } from '@api/game-server/client';

// Adapted from Dan Abramov's example: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// Permission is granted in the link above to reuse/adapt the original code
function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const REFRESH_DELAY_MS = 10000; // 10 seconds

async function getData(limit?: number, worlds?: string[], createdAfter?: Date) {
  try {
    const data = await feed.list(limit, worlds, createdAfter);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export interface FeedCardEntity extends Entity {
  worlds: {
    world_seed: string;
    display_name: string;
  };
}

export interface FeedCardArtifact extends Story {
  worlds: {
    display_name: string;
    name: string;
  };
}

interface FeedListProps {
  limit?: number;
  worlds?: string[];
  createdAfter?: Date;
}

const FeedList = ({ limit, worlds }: FeedListProps) => {
  const [items, setItems] = useState<(FeedCardEntity | FeedCardArtifact)[]>([]);

  async function fetchItems(
    isFirstFetch: boolean,
    oldItems: (FeedCardEntity | FeedCardArtifact)[],
  ) {
    // On subsequent requests, we want to only get new data since our last request
    const timestamp = isFirstFetch
      ? undefined
      : new Date(Date.now() - REFRESH_DELAY_MS);

    getData(limit, worlds, timestamp).then((newItems) => {
      if (newItems?.length) {
        let newData = [...newItems, ...oldItems];

        if (limit && newData.length > limit) {
          newData = newData.slice(0, limit);
        }

        setItems(newData);
      }
    });
  }

  // Initial fetch on load
  useEffect(() => {
    fetchItems(true, items);
  }, []);

  // Set up recurring fetches on an interval while maintaining a reference to the latest `items` state
  useInterval(() => {
    fetchItems(false, items);
  }, REFRESH_DELAY_MS);

  return (
    <StrictMode>
      {items ? (
        <div className="grid auto-cols-max grid-flow-col overflow-y-auto gap-4 pb-4 snap-x">
          {items.map((entity, i) => (
            <div
              key={i}
              className="motion-safe:animate-fadeIn motion-safe:opacity-0 max-w-fit h-full snap-center"
              style={{
                animationDelay: `${i * 100}ms`,
                animationFillMode: 'forwards',
              }}
            >
              {'artifact' in entity && entity.artifact ? (
                <ArtifactCard artifact={entity.artifact} />
              ) : (
                <Card entity={entity as FeedCardEntity} hidePlayerText />
              )}
            </div>
          ))}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </StrictMode>
  );
};

export default FeedList;
